import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './auth/authReducer'
import stripeReducer from './stripe/stripeReducer'
import subscriptionReducer from './subscription/subscriptionReducer'
import proposalReducer from './proposal/proposalReducer'
import themeReducer from './theme/themeReducer'
import userReducer from './user/userReducer'
import buyerProjectReducer from './buyerProject/buyerProjectReducer'
import sellerProjectReducer from './sellerProject/sellerProjectReducer'
import socketReducer from './socket/socketReducer'
import adminProjectReducer from './adminProject/adminProjectReducer'
import leadReducer from './leads/leadReducer'

// Custom transform to set user to null when not found
const authTransform = createTransform(
  (inboundState, key) => {
    if (key === 'auth' && inboundState) {
      return {
        ...(inboundState as object),
        user: (inboundState as any)?.user || null,
      }
    }
    return inboundState
  },
  (outboundState) => {
    return outboundState
  }
)

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'theme', 'stripe', 'subscriptions', 'proposals'],
  blacklist: ['projects', 'users', 'socket', 'leads', 'adminProjects'],
  transforms: [authTransform],
}

const rootReducer = combineReducers({
  auth: authReducer,
  stripe: stripeReducer,
  subscriptions: subscriptionReducer,
  buyerProjects: buyerProjectReducer,
  sellerProjects: sellerProjectReducer,
  proposals: proposalReducer,
  theme: themeReducer,
  users: userReducer,
  socket: socketReducer,
  adminProjects: adminProjectReducer,
  leads: leadReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
